import styled from "@emotion/styled"
import React from "react"
import { MobileMediaQuery } from "../styles/constants"
import { ContentfulImageProps, Markdown } from "../types/contentful"
import { ContentfulImage } from "./ContentfulImage"
import Typography, { TypographyMarkdown, typographyStyles } from "./typography"

const Wrapper = styled.div`
  width: 280px;
  margin: 2rem;

  ${MobileMediaQuery} {
    margin: 0;
  }
`

const Name = styled(Typography)`
  display: block;
  margin-top: 3rem;
`

const ImageWrapper = styled.div`
  height: 130px;
`

const MarkdownWrapper = styled(TypographyMarkdown)`
  p {
    ${typographyStyles.bodySmall}
  }
`

export const PerksAndBenefit = ({
  title,
  description,
  image,
}: {
  title: string
  description: Markdown
  image: ContentfulImageProps
}): JSX.Element => (
  <Wrapper>
    <ImageWrapper>
      <ContentfulImage image={image} />
    </ImageWrapper>
    <Name variant="subtitleLarge">{title}</Name>
    <MarkdownWrapper
      dangerouslySetInnerHTML={{ __html: description.childMarkdownRemark.html }}
    />
  </Wrapper>
)
