import styled from "@emotion/styled"
import React from "react"
import { slugify } from "../../shared/utils"
import colors from "../styles/colors"
import Link from "./link"
import Typography, { typographyStyles } from "./typography"

const Wrapper = styled.table`
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 2fr 1fr;
  grid-auto-rows: minmax(50px, auto);
`
const Header = styled.thead`
  display: contents;
`
const Row = styled.tr<{ even: boolean }>`
  display: contents;

  & > td {
    background-color: ${p => (p.even ? colors.lightgrey : colors.white)};
  }
`
const RowItem = styled.td``
const ColumnTitle = styled.th`
  border-bottom: 1px solid #000;
`

const RowLink = styled(Link)`
  ${typographyStyles.bodySmall};
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  color: ${colors.primary};
  padding: 5px;
`
const RowTypo = styled(Typography)`
  display: flex;
  align-items: center;
  height: 100%;
`

type Position = {
  title: string
  location: string
  department: string
}

interface CareersListProps {
  positions: Position[]
}

export const CareersList = React.forwardRef(
  (
    { positions }: CareersListProps,
    ref: React.ForwardedRef<HTMLTableElement>
  ): JSX.Element => {
    return (
      <Wrapper ref={ref}>
        <Header>
          <ColumnTitle>Role</ColumnTitle>
          <ColumnTitle>Team</ColumnTitle>
          <ColumnTitle>Location</ColumnTitle>
        </Header>
        <tbody style={{ display: "contents" }}>
          {positions
            .sort((a, b) => (a.department < b.department ? -1 : 1))
            .map((pos, index) => (
              <Row even={Boolean(index % 2)}>
                <RowItem>
                  <RowLink to={`/careers/${slugify(pos.title)}/`}>
                    {pos.title}
                  </RowLink>
                </RowItem>
                <RowItem>
                  <RowTypo variant="bodySmall">{pos.department}</RowTypo>
                </RowItem>
                <RowItem>
                  <RowTypo variant="bodySmall">{pos.location}</RowTypo>
                </RowItem>
              </Row>
            ))}
        </tbody>
      </Wrapper>
    )
  }
)
