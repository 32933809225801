import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import colors from "../styles/colors"
import { ContentfulImageProps } from "../types/contentful"
import Typography, { TypographyMarkdown, typographyStyles } from "./typography"

const Wrapper = styled.div`
  max-width: 325px;
`
const ImageWrapper = styled.div`
  border-radius: 4px;
`
const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.darkgrey};
  max-width: 270px;
`
const Role = styled(Typography)`
  color: ${colors.grey};
`

const MarkdownWrapper = styled(TypographyMarkdown)`
  p {
    ${typographyStyles.bodySmall}
  }
`

export const Maven = ({
  quote,
  name,
  role,
  image,
}: {
  quote: string
  name: string
  role: string
  image?: ContentfulImageProps
}): JSX.Element => (
  <Wrapper>
    {image.gatsbyImageData && (
      <ImageWrapper>
        <GatsbyImage image={image.gatsbyImageData} alt={name ?? "image"} />
      </ImageWrapper>
    )}
    <Bottom>
      <MarkdownWrapper dangerouslySetInnerHTML={{ __html: quote }} />
      <Typography variant="bodySmall">{name}</Typography>
      <Role variant="bodySmall">{role}</Role>
    </Bottom>
  </Wrapper>
)
