import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { HoverButton } from "../components/button"
import Card, { CardTitle, DoubleCard } from "../components/card"
import { CareersList } from "../components/CareersList"
import Layout from "../components/layout"
import { Maven } from "../components/Maven"
import { PerksAndBenefit } from "../components/PerksAndBenefit"
import SEO from "../components/seo"
import { Spacer } from "../components/Spacer"
import Typography, {
  TypographyMarkdown,
  typographyStyles,
} from "../components/typography"
import colors from "../styles/colors"
import {
  BORDER_RADIUS_LARGE,
  DesktopMediaQuery,
  MobileMediaQuery,
  MOBILE_PADDING_HORIZONTAL,
  MOBILE_PADDING_VERTICAL,
  PADDING_HORIZONTAL,
  PADDING_VERTICAL,
  SPACING_LARGE,
  SPACING_SMALL,
} from "../styles/constants"
import {
  ContentfulImageProps,
  Markdown,
  MavenProps,
  PerksAndBenefitProps,
} from "../types/contentful"

type Careers = {
  contentfulCareers: {
    title: string | null
    ctaHeroText: string
    introText: Markdown
    mavensTitle: string
    mavens: MavenProps[]
    introSecondText: Markdown
    introSecondImage: ContentfulImageProps
    whyMavenoidText: Markdown
    whyNotMavenoidText: Markdown
    PerksAndBenefitsTitle: string
    perksAndBenefits: PerksAndBenefitProps[]
    openPositionsTitle: string
  } | null

  allLever: {
    edges: {
      node: {
        title: string
        categories: { location: string; team: string }
      }
    }[]
  }
}

interface LandingPageProps {
  data: Careers
  pageContext: string
}

const MarkdownWrapper = styled(TypographyMarkdown)`
  margin-top: 1rem;
  p {
    ${typographyStyles.h5}
  }
`

const Hero = styled.div`
  max-width: 1360px;
  background-color: ${colors.dark};
  border-radius: ${BORDER_RADIUS_LARGE}px;
  margin: 0 auto;
  min-height: 600px;
  display: flex;

  ${MobileMediaQuery} {
    min-height: 300px;
    border-radius: 0;
    margin: 0 -4px;
  }
`
const HeroWrapper = styled.div`
  border-radius: ${BORDER_RADIUS_LARGE}px;
  padding: ${PADDING_VERTICAL}px ${PADDING_HORIZONTAL}px;
  color: ${colors.white};
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  ${MobileMediaQuery} {
    padding: ${MOBILE_PADDING_VERTICAL}px ${MOBILE_PADDING_HORIZONTAL}px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const MavensWrapper = styled.div`
  display: flex;
  margin-top: 2rem;

  ${MobileMediaQuery} {
    flex-direction: column;
    & > :not(:first-of-type) {
      margin-left: 0rem;
      margin-top: 4rem;
    }
  }
  ${DesktopMediaQuery} {
    & > :not(:first-of-type) {
      margin-left: 2rem;
    }
  }
`

const CombinedWhy = styled(TypographyMarkdown)`
  display: flex;
  flex-direction: column;
  color: ${colors.primary};

  p {
    ${typographyStyles.bodySmall};
    color: ${colors.darkgrey};
  }

  strong {
    color: ${colors.primary};
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const WhyCard = styled(CombinedWhy)`
  p {
    ${typographyStyles.bodySmall};
    color: ${colors.darkgrey};
  }
`
const WhyNotCard = styled(CombinedWhy)`
  p {
    ${typographyStyles.bodySmall};
    color: ${colors.white};
  }
`

const PerksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const RoundButton = styled(HoverButton)`
  border-radius: 100px;
  height: 90px;
  width: 330px;
  justify-content: center;
  text-transform: none;
  ${typographyStyles.bodyLarge}
`

const CareersPage = ({ data }: LandingPageProps): JSX.Element => {
  const openPositionRef = React.useRef<HTMLTableElement>()
  const { edges: offers } = data.allLever
  const { title, introText } = data.contentfulCareers
  const { contentfulCareers: careers } = data

  return (
    <Layout newsletter={null}>
      <SEO title={title} />
      <Hero>
        <HeroWrapper>
          <Typography
            variant="hero"
            style={{ maxWidth: 800, marginBottom: "4rem" }}
          >
            Are you a problem solver?
          </Typography>
          <RoundButton
            onClick={() => {
              window.scroll({
                top: openPositionRef.current.getBoundingClientRect().y - 150,
                left: 0,
                behavior: "smooth",
              })
            }}
          >
            See open positions
          </RoundButton>
        </HeroWrapper>
      </Hero>
      <Card variant="white">
        <MarkdownWrapper
          dangerouslySetInnerHTML={{
            __html: introText.childMarkdownRemark.html,
          }}
        />
        <CardTitle variant="h2" style={{ marginTop: "2rem" }}>
          {careers.mavensTitle}
        </CardTitle>
        <MavensWrapper>
          {careers.mavens.map(maven => (
            <Maven
              quote={maven.quote.childMarkdownRemark.html}
              image={maven.profilePicture}
              name={maven.name}
              role={maven.role}
            />
          ))}
        </MavensWrapper>
        <Spacer height={SPACING_LARGE} />
        <MarkdownWrapper
          dangerouslySetInnerHTML={{
            __html: careers.introSecondText.childMarkdownRemark.html,
          }}
        />
        <Spacer height={SPACING_SMALL} />
        <GatsbyImage
          image={careers.introSecondImage.gatsbyImageData}
          alt={careers.introSecondImage.title ?? "image"}
        />
        <Spacer height={SPACING_LARGE} />
      </Card>
      <DoubleCard style={{ maxWidth: 1360 }}>
        <Card variant="lightgrey">
          <WhyCard
            dangerouslySetInnerHTML={{
              __html: careers.whyMavenoidText.childMarkdownRemark.html,
            }}
          />
        </Card>
        <Card variant="darkgrey">
          <WhyNotCard
            dangerouslySetInnerHTML={{
              __html: careers.whyNotMavenoidText.childMarkdownRemark.html,
            }}
          />
        </Card>
      </DoubleCard>
      <Card variant="white" noMinHeight>
        <CardTitle variant="h2">{careers.PerksAndBenefitsTitle}</CardTitle>
        <PerksWrapper>
          {careers.perksAndBenefits.map(perk => (
            <PerksAndBenefit
              title={perk.name}
              description={perk.description}
              image={perk.image}
            />
          ))}
        </PerksWrapper>
      </Card>
      <Card variant="white">
        <CardTitle variant="h2">{careers.openPositionsTitle}</CardTitle>
        <CareersList
          ref={openPositionRef}
          positions={offers.map(({ node }) => ({
            title: node.title,
            location: node.categories.location,
            department: node.categories.team,
          }))}
        />
      </Card>
    </Layout>
  )
}

export default CareersPage

export const query = graphql`
  query CareersPage {
    allLever {
      edges {
        node {
          title: text
          categories {
            team
            location
          }
        }
      }
    }

    contentfulCareers {
      title
      introText {
        childMarkdownRemark {
          html
        }
      }
      mavensTitle
      introSecondText {
        childMarkdownRemark {
          html
        }
      }
      introSecondImage {
        title
        gatsbyImageData
      }
      whyMavenoidText {
        childMarkdownRemark {
          html
        }
      }
      whyNotMavenoidText {
        childMarkdownRemark {
          html
        }
      }
      PerksAndBenefitsTitle
      perksAndBenefits {
        name
        image {
          ...contentfulImageGatsby
        }
        description {
          childMarkdownRemark {
            html
          }
        }
      }
      openPositionsTitle
      mavens {
        name
        quote {
          childMarkdownRemark {
            html
          }
        }
        profilePicture {
          gatsbyImageData
        }
        role
      }
    }
  }
`
